import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import WidgetLoader from "../components/widgets/WidgetLoader"

const Instagram = loadable(() => import("../components/widgets/Instagram"))

const HomePage = ({ data: { sanityStartPage }, pageContext }) => {
  const { widgets } = sanityStartPage

  return (
    <Layout
      meta={{ title: "Rondure" }}
      pageContext={pageContext}
      noHeaderBorder
      absoluteHeader
    >
      {widgets && widgets.length && <WidgetLoader widgets={widgets} />}
      {false && <Instagram />}
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query {
    sanityStartPage {
      widgets {
        ...TextWidget
        ...Grid
        ...Hero
        ...ProductSlider
        ...UspWidget
        ...Countdown
        ...Image
        ...Video
        ...IconGrid
        ...Instruction
        ...Instagram
      }
    }
  }
`
